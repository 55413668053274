import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { css, cx } from '@emotion/css/macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MaterialTextField from '@material-ui/core/TextField';
import { DeleteForever, HistoryEdu, Save, TableChart } from '@mui/icons-material';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Chip } from '@mui/material';
import { styles } from '../constants/styles';
import Data from '../UserData';
import { useAllowedMethods, useReportAccess } from '../../auth/hooks';
import { Button, Loading } from '../../core/components';
import MaterialDialog from '../../flyout/components/MaterialDialog';
import { useAPI, useToggle } from '../../core/hooks';
import { PureRadioGroupField, PureTextField } from '../../formaggio';
import ReportContextWrapper, { useReportContext } from '../context/ReportContext';
import { useFieldHandler } from '../../core/helpers/stateActionHelpers';
import { ClientReplicator } from '../Replicator';
import { usePermalinkHighlightQS, useScrollToReportItem } from '../hooks';
import { highlightCellClass } from '../LinkButton';
import { ReportAccessWrapper } from '../../auth/ReportAccessWrapper';
import { useProjectInfo } from '../../project/hooks';
import { useItemContext } from '../context/ItemContext';
import { RequirementApproach } from '../../../graphql/typescript';
import SummaryNAReasons from '../SummaryNAReasons';
import ProjectTypeInputWrapper from '../ProjectTypeInputWrapper';
import OverlayPanel from '../OverlayPanel';
import { TableStickyCell } from '../../../shared/components';
import ControlsMatrixList from '../controlsMatrix/ControlsMatrixList';
import SimplifiedButton from '../simplifiedControls/SimplifiedButton';

enum ApproachType {
	Defined = 0,
	Customized = 1,
}

const approachTypes: Record<ApproachType, any> = {
	[ApproachType.Defined]: {
		title: 'Defined with Compensating Control',
		value: ApproachType.Defined,
	},
	[ApproachType.Customized]: { title: 'Customized', value: ApproachType.Customized },
};

const headers = css`
	${styles.rocV4GreenTableHeader}${styles.rocCenter}
`;

const NOT_APPLICABLE_VALUE = '2';
const SUMMARY_FIELD_NAME = 'summaryOfAssessmentFindingsState';
const WHY_SELECTED_FIELD_NAME = 'whySelected';

export function RequirementInput({ onChange, value = {}, onFocus, additional }: any) {
	const { isAQSA } = useReportAccess();

	const disabled = additional?.disabled || false;

	const changeValue = React.useCallback(
		(e) => onChange({ ...value, [e.target.name]: e.target.value }),
		[onChange, value],
	);

	return (
		<div>
			<RadioGroup
				style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}
				name={SUMMARY_FIELD_NAME}
				value={String(value[SUMMARY_FIELD_NAME])}
				onFocus={onFocus}
				onMouseEnter={onFocus}
				onChange={changeValue}
			>
				<FormControlLabel
					value="0"
					control={<Radio />}
					label="In Place"
					disabled={!isAQSA || disabled}
				/>
				<FormControlLabel
					value={NOT_APPLICABLE_VALUE}
					control={<Radio />}
					label="Not Applicable"
					disabled={!isAQSA || disabled}
				/>
				<FormControlLabel
					value="3"
					control={<Radio />}
					label="Not Tested"
					disabled={!isAQSA || disabled}
				/>
				<FormControlLabel
					value="4"
					control={<Radio />}
					label="Not in Place"
					disabled={!isAQSA || disabled}
				/>
			</RadioGroup>
			<MaterialTextField
				label="Why the assessment finding was selected"
				helperText={
					!disabled &&
					'Include all details as noted in the “Required Reporting” column of the table in Assessment Findings in the ROC Template Instructions'
				}
				fullWidth
				multiline
				variant="outlined"
				size="small"
				value={value[WHY_SELECTED_FIELD_NAME] || ''}
				name={WHY_SELECTED_FIELD_NAME}
				onChange={changeValue}
				disabled={!isAQSA || disabled}
			/>
		</div>
	);
}

const approachContainerControlsClass = css`
	display: flex;
	align-items: center;
`;

const aspectsClass = css`
	flex: 1 1 auto;
	margin: 8px !important;
`;

export function ApproachContainerControls({ aspects = '' }: RequirementApproach) {
	const { 'Approaches/Update': canEdit, 'Approaches/Delete': canDelete } = useAllowedMethods();
	const { projectId, refresh } = useReportContext();
	const { itemId } = useItemContext();

	const [aspectsState, setAspects] = React.useState(aspects);
	const handleAspects = useFieldHandler(setAspects);

	React.useEffect(() => {
		if (itemId) setAspects(aspects);
	}, [aspects, itemId, setAspects]);

	const { fetchAPI } = useAPI();

	const deleteApproach = React.useCallback(
		() =>
			fetchAPI({
				query: `Approaches/Delete/${projectId}/${itemId}`,
				method: 'DELETE',
				onSuccess: refresh,
				confirmation: {
					title: 'Are you sure?',
					message: 'All linked data will be deleted',
				},
			}),
		[fetchAPI, itemId, projectId, refresh],
	);

	const saveApproach = React.useCallback(
		() =>
			fetchAPI({
				query: `Approaches/Update/${projectId}/${itemId}`,
				method: 'PUT',
				onSuccess: () => refresh(),
				params: { aspects: aspectsState },
			}),
		[aspectsState, fetchAPI, itemId, projectId, refresh],
	);

	return (
		<div className={approachContainerControlsClass}>
			{canEdit && (
				<Button startIcon={<Save />} disabled={aspectsState === aspects} onClick={saveApproach}>
					Save changes
				</Button>
			)}
			<PureTextField
				label="Identify the aspect(s) of the requirement where approach below was used"
				variant="outlined"
				fullWidth
				multiline
				className={aspectsClass}
				value={aspectsState}
				onChange={handleAspects}
				readOnly={!canEdit}
			/>
			{canDelete && (
				<Button startIcon={<DeleteForever />} onClick={deleteApproach} size="small">
					Delete approach
				</Button>
			)}
		</div>
	);
}

function RenderedApproach({ projectId, refresh, view }: any) {
	const { data: projectInfo } = useProjectInfo(projectId);
	const { paymentChannels, roles, workflowRole, customSaq = [] } = projectInfo;
	const { fetchAPI } = useAPI();

	if (!view?.items || !paymentChannels) return <Loading />;

	return (
		<ReportAccessWrapper
			projectRole={roles}
			paymentChannels={paymentChannels}
			reportFilter={{}}
			workflowRole={workflowRole}
			customSaqList={customSaq}
		>
			<ReportContextWrapper
				{...view}
				projectInfo={projectInfo}
				projectId={projectId}
				refresh={refresh}
				isTokenPage
			>
				<ClientReplicator
					modelId={projectId}
					refresh={refresh}
					fetchAPI={fetchAPI}
					{...view?.items[0]}
				/>
			</ReportContextWrapper>
		</ReportAccessWrapper>
	);
}

function isApproachUsed(
	value: ApproachType,
	compensatingControlUsed?: boolean,
	customizedApproachUsed?: boolean,
) {
	switch (value) {
		case ApproachType.Defined:
			return compensatingControlUsed;
		case ApproachType.Customized:
			return customizedApproachUsed;
		default:
			return false;
	}
}

function renderApproachOptions(
	compensatingControlUsed?: boolean,
	customizedApproachUsed?: boolean,
	isCustomizedApproachForbidden?: boolean,
	isQSA?: boolean,
) {
	return Object.keys(approachTypes).map((key) => {
		const approach = approachTypes[key];
		const isUsed = isApproachUsed(approach.value, compensatingControlUsed, customizedApproachUsed);
		const isCustomized = key === String(ApproachType.Customized);
		const isForbidden = isCustomized && isCustomizedApproachForbidden;

		return (
			<FormControlLabel
				value={approach.value}
				control={<Radio />}
				label={
					approach.title +
					(isUsed ? ' (in use)' : '') +
					(isForbidden ? ' (not eligible for this requirement)' : '')
				}
				key={approach.title}
				disabled={isForbidden || (!isCustomized && !isQSA)}
			/>
		);
	});
}

const controlPanelClass = css`
	position: sticky;
	top: 0;
	background: white;
	z-index: 2;
	align-items: center;
	display: flex;
`;

function ApproachList({
	requirementId,
	compensatingControlUsed,
	customizedApproachUsed,
	isCustomizedApproachForbidden,
}: any) {
	const { isQSA } = useReportAccess();
	const { 'Approaches/Add': canAdd } = useAllowedMethods();
	const { projectId, refresh: refreshReport } = useReportContext();

	const approachHandles = React.useMemo(() => {
		let initialValue: ApproachType = ApproachType.Defined;

		if (isCustomizedApproachForbidden) initialValue = ApproachType.Defined;
		else {
			if (customizedApproachUsed) initialValue = ApproachType.Customized;
			if (isQSA && compensatingControlUsed) initialValue = ApproachType.Defined;
		}

		return {
			initialValue: approachTypes[initialValue].value,
			render: renderApproachOptions(
				compensatingControlUsed,
				customizedApproachUsed,
				isCustomizedApproachForbidden,
				isQSA,
			),
		};
	}, [compensatingControlUsed, customizedApproachUsed, isCustomizedApproachForbidden, isQSA]);

	const [type, setType] = React.useState<ApproachType>(approachHandles.initialValue);

	const showUseButton = React.useMemo(() => {
		if (type === approachTypes[ApproachType.Defined].value)
			return !compensatingControlUsed && isQSA;
		if (type === approachTypes[ApproachType.Customized].value) return !customizedApproachUsed;
		return true;
	}, [compensatingControlUsed, customizedApproachUsed, isQSA, type]);

	const [controlsMatrixIds, setControlsMatrixIds] = React.useState<string[]>([]);
	const selectControlsMatrixId = React.useCallback(
		(id) =>
			setControlsMatrixIds((prevState) => {
				if (prevState.filter((i) => i === id).length > 0) {
					const newList = [...prevState];
					newList.splice(prevState.indexOf(id), 1);
					return newList;
				}
				return [...prevState, id];
			}),
		[],
	);

	const {
		state,
		fetchAPI,
		initialFetch: refetchApproach,
	} = useAPI({
		props: {
			query: `Approaches/View/${projectId}/${requirementId}/${type}`,
			onSuccess: (response = {}) =>
				setControlsMatrixIds(
					response?.data?.root?.requirementApproach?.linkedControlsMatrixIds || [],
				),
		},
		autoLoad: true,
	});

	const { isOn, toggleOff, toggleOn } = useToggle();

	const refresh = React.useCallback(
		(newType?: number) => {
			refetchApproach({
				query: `Approaches/View/${projectId}/${requirementId}/${
					typeof newType === 'number' ? newType : type
				}`,
				onSuccess: (response) => {
					setControlsMatrixIds(
						response?.data?.root?.requirementApproach?.linkedControlsMatrixIds || [],
					);
					refreshReport();
				},
			});
		},
		[refetchApproach, projectId, requirementId, type, refreshReport],
	);

	const closeControlsWindow = React.useCallback(() => {
		toggleOff();
		if (!showUseButton) refresh();
	}, [refresh, showUseButton, toggleOff]);

	const changeType = React.useCallback(
		(e) => {
			setType(Number(e.target.value));
			refresh(Number(e.target.value));
		},
		[refresh],
	);

	const addApproach = React.useCallback(
		() =>
			fetchAPI({
				query: `Approaches/Add/${projectId}`,
				method: 'POST',
				params: { type, requirementId, linkedControlsMatrixIds: controlsMatrixIds },
				onSuccess: () => {
					setControlsMatrixIds([]);
					refresh();
				},
			}),
		[fetchAPI, projectId, type, requirementId, controlsMatrixIds, refresh],
	);

	const approachItem = state?.autodata?.data?.root || {};
	const saveApproach = React.useCallback(
		() =>
			fetchAPI({
				query: `Approaches/Update/${projectId}/${approachItem.itemId}`,
				method: 'PUT',
				onSuccess: () => refetchApproach(),
				params: { ...approachItem.requirementApproach, linkedControlsMatrixIds: controlsMatrixIds },
			}),
		[
			approachItem.itemId,
			approachItem.requirementApproach,
			controlsMatrixIds,
			fetchAPI,
			refetchApproach,
			projectId,
		],
	);

	const title = React.useMemo(() => {
		if (customizedApproachUsed) return 'View/edit controls';
		return controlsMatrixIds.length > 0 ? 'View selected controls' : 'Select controls';
	}, [controlsMatrixIds, customizedApproachUsed]);
	const isCustomized = type === ApproachType.Customized;

	const isControlMatrixIdsUnchanged = React.useMemo(
		() =>
			JSON.stringify(approachItem?.requirementApproach?.linkedControlsMatrixIds) ===
			JSON.stringify(controlsMatrixIds), //Quick dumb hack for array comparison
		[approachItem?.requirementApproach?.linkedControlsMatrixIds, controlsMatrixIds],
	);

	return (
		<>
			<div className={controlPanelClass}>
				<PureRadioGroupField label="Type" onChange={changeType} value={type}>
					{approachHandles.render}
				</PureRadioGroupField>
				<ButtonGroup>
					{canAdd && showUseButton && (
						<Button startIcon={<HistoryEdu />} onClick={addApproach} size="small">
							Use
						</Button>
					)}
					{isCustomized && (
						<Button startIcon={<TableChart />} onClick={toggleOn} size="small">
							{title}
						</Button>
					)}
				</ButtonGroup>
			</div>
			{!!projectId && !!state?.autodata?.data && (
				<RenderedApproach projectId={projectId} refresh={refresh} view={state?.autodata?.data} />
			)}
			{projectId && canAdd && isCustomized && (
				<MaterialDialog
					onClose={closeControlsWindow}
					isOpen={isOn}
					title={title}
					actions={
						<>
							{!showUseButton && (
								<Button
									startIcon={<Save />}
									onClick={saveApproach}
									disabled={isControlMatrixIdsUnchanged}
								>
									Save changes
								</Button>
							)}
							<Button onClick={closeControlsWindow}>Close</Button>
						</>
					}
					fullScreen
				>
					<ControlsMatrixList
						projectId={projectId}
						controlsMatrixIds={controlsMatrixIds}
						selectControlsMatrixId={selectControlsMatrixId}
					/>
				</MaterialDialog>
			)}
		</>
	);
}

function ApproachChip({ label, enabled }) {
	return (
		<Chip
			label={`${label} ${enabled ? '' : 'not '}in use`}
			style={{ marginTop: '8px' }}
			variant={enabled ? 'filled' : 'outlined'}
			color={enabled ? 'warning' : 'default'}
		/>
	);
}

const approachesClass = css`
	display: flex;
	margin-left: 8px;
	flex-direction: column;
	align-items: flex-start;
`;

const dialogClass = css`
	padding: 0 16px !important;
`;

const approachDialogRootClass = css`
	min-width: 800px;
`;

function Approaches({
	requirementId,
	customizedApproachUsed,
	compensatingControlUsed,
	isCustomizedApproachForbidden,
	initialMatrixId,
}: any) {
	const { refresh } = useReportContext();
	const { isOn, toggle, toggleOff } = useToggle();
	const close = React.useCallback(() => {
		toggleOff();
		refresh();
	}, [refresh, toggleOff]);

	return (
		<div className={approachesClass}>
			<SimplifiedButton startIcon={<HistoryEdu />} size="small" variant="outlined" onClick={toggle}>
				Approaches
			</SimplifiedButton>
			<MaterialDialog
				title="Approaches"
				isOpen={isOn}
				contentClass={dialogClass}
				onClose={close}
				actions={
					<>
						<Button onClick={close}>Close</Button>
					</>
				}
				className={approachDialogRootClass}
				fullScreen
			>
				<ApproachList
					initialMatrixId={initialMatrixId}
					requirementId={requirementId}
					customizedApproachUsed={customizedApproachUsed}
					compensatingControlUsed={compensatingControlUsed}
					isCustomizedApproachForbidden={isCustomizedApproachForbidden}
				/>
			</MaterialDialog>
			<ApproachChip label="Customized approach" enabled={customizedApproachUsed} />
			<ApproachChip label="Compensating control" enabled={compensatingControlUsed} />
		</div>
	);
}

const dataClass = css`
	display: flex;
	align-items: flex-start;
`;

function retrievedValue(value: any) {
	return value;
}

function getData(data: any) {
	return {
		[SUMMARY_FIELD_NAME]: data[SUMMARY_FIELD_NAME],
		[WHY_SELECTED_FIELD_NAME]: data[WHY_SELECTED_FIELD_NAME],
	};
}

function validation(value: any = {}) {
	if (typeof value[SUMMARY_FIELD_NAME] === 'undefined')
		return 'Requirement state should be selected';
	if (!value[WHY_SELECTED_FIELD_NAME]) return 'Specify selection reason';

	return undefined;
}

export default function Requirement({
	requirement,
	data = [],
	customizedApproachUsed,
	compensatingControlUsed,
	isCustomizedApproachForbidden,
	...rest
}: any) {
	const { 'Approaches/View': canViewApproaches } = useAllowedMethods();
	const { projectId } = useReportContext();

	//Highlight
	const highlightQS = usePermalinkHighlightQS();
	const ref = useScrollToReportItem(rest.itemId);

	const { isOn, toggleOn, toggleOff } = useToggle();

	return (
		<>
			<TableRow>
				<TableCell className={headers} colSpan={10}>
					PCI DSS Requirement
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell colSpan={10} dangerouslySetInnerHTML={{ __html: requirement }} />
			</TableRow>
			<TableRow>
				<TableCell className={headers} colSpan={10}>
					<p>Assessment Findings</p>(select one)
				</TableCell>
			</TableRow>
			<TableRow>
				<TableStickyCell
					colSpan={10}
					ref={ref}
					className={cx(!!highlightQS && rest.itemId === highlightQS && highlightCellClass)}
					onFocus={toggleOn}
					onMouseEnter={toggleOn}
					onMouseLeave={toggleOff}
				>
					<OverlayPanel isOn={isOn} />
					<ProjectTypeInputWrapper>
						<SummaryNAReasons notApplicableReasons={rest.notApplicableReasons}>
							<div className={dataClass}>
								<Data
									{...rest}
									retrievedValue={retrievedValue}
									inputElement={RequirementInput}
									manualSave
									data={data}
									getData={getData}
									validation={validation}
								/>
								{projectId && canViewApproaches && (
									<Approaches
										customizedApproachUsed={customizedApproachUsed}
										compensatingControlUsed={compensatingControlUsed}
										isCustomizedApproachForbidden={isCustomizedApproachForbidden}
										requirementId={rest.itemId}
										saq={rest.saq}
									/>
								)}
							</div>
						</SummaryNAReasons>
					</ProjectTypeInputWrapper>
				</TableStickyCell>
			</TableRow>
		</>
	);
}
